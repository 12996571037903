.load_more_page{
    margin-top: 70px;
    padding-left: 50px !important;
    padding-right: 50px !important;
}

@media only screen and (max-width: 767px) {
    .load_more_page{
        padding: 0px !important;
    }

    .load_more_page{
        margin-top: 100px;
    }
}

