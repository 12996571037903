.result-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    list-style: none;
    border: 1px solid #ccc;
    padding: 0;
    margin: 0;
    border-radius: 20px !important;
    min-height: 200px;
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;    
}

.result-list li {
    padding: 8px;
    cursor: pointer;
    
  }
  
.result-list li:hover {
    background-color: #e9e9e9;
    border-radius: 20px !important;
}

.result-image {
    width: 50%;
    background-color: #ff66;
}

.nav_search {
    width: 550px !important;
    border-radius: 20px !important;
    background-color: transparent !important;
    border: 2px solid #959595 !important;
    color: #000000 !important;
    box-shadow: none !important;
    background-color: #fff !important;
    
    /* border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important; */
    /* border-right: 0px !important; */
}