.signup__process {
    max-width: 100%;
    padding: 24px 24px 0;
    background-color: #11161c !important;
}

.signup__process label {
    margin-bottom: 0px;
    font-size: 12px;
}

.signup__process input, .signup__process input:focus {
    background-color: #1c252f;
    color: #8c8c8c;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    padding: 4px;
    width: 100%;
    border: none;
    border-color: rgba(0, 0, 0, .12);
    margin: 5px 0px 30px 0px;
    box-shadow: none !important;
    padding-left: 10px;
}

.signup__process .btn__signup, .signup__process .btn__signup:hover {
    color: #000 !important;
    background-color: #ffe01b !important;
    height: 38px;
    min-width: 88px;
    border-radius: 8px;
    padding-bottom: 10px;
}