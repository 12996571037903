body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page_body {
  background-color: #16161a !important;
  min-height: 100vh;
}

.slick-slide {
  padding: 0px 10px;
  position: relative;
}

.cast_section .slick-slide img {
  height: 175px;
  width: 100%;
}

.slick-slide .card-title {
  font-size: 14px;
}

.slick-slide .card-text {
  font-size: 14px;
}


.search_results {
  padding: 65px 20px;
}

/* h1, h2, h3, h4, h5, h6, label {
  color: #fffffe !important;
} */

p {
  color: #94a1b2 !important;
}

.cast_section .slick-slide .card {
  min-height: 300px;
}

.no_data {
  height: 112px;
  padding-top: 50px;
}

.btn_back {
  background-color: #ffe01b !important;
  font-weight: 700 !important;
  border-radius: 20px !important;
}

.btn_back i {
  font-weight: bold;
  font-size: 20px;
  margin-top: 3px;
}

.slick-dots {
  display: none !important;
}

.delete_icon{
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .saerch_btn_back {
    margin-top: 40px;
  }

  .page_body {
    padding: 0px 14px;
  }

  .watchlist_section {
    margin-top: 150px !important;
  }

  .footer_section {
    padding: 0px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 767px) {

  .hompage_slider {
    padding: 0px 15px;
  }

  .saerch_btn_back {
    margin-top: 110px;
  }

  .filter_results {
    margin: 0px 20px;
  }
}

@media screen and (min-width: 767px) {

}

@media screen and (min-width: 1200px) {
  .slick-prev:before, .slick-next:before {
    font-size: 45px !important;
  }

  .hompage_slider .slick-list {
    margin: 0px 20px;
  }

  .slick-prev {
    left: -30px !important;
  }

  .slick-next {
    right: -5px !important;
  }
}