.filter_results{
    margin: 0px 40px;
}

.filter_results .slick-list{
    margin: 0px 15px;
}

@media only screen and (max-width: 767px) {
    .filter_results{
        margin: 0px;
    }

    .filter_results .slick-list{
        margin: 0px;
    }
}