.footer_section {
    padding: 0px 50px;
    border-top: 1px solid #fff;
}

.footer_logo {
    width: 75px;
    margin-left: 5px;
}

.footer_links{
    margin-top: 15px;
}

.footer_links ul{
    display: flex;
    justify-content: space-between;
}

.footer_links ul li{
    list-style: none;
    color: #ffe01b;
}

.footer_links ul li a{
    color: #ffe01b !important;
    text-decoration: none !important;
}

@media only screen and (max-width: 767px) {
    .footer_section{
      padding: 0px;
    }
  }