.email__password__signin {
    max-width: 100%;
    padding: 24px 24px 24px;
    background-color: #11161c !important;
}

.email__password__signin label {
    margin-bottom: 0px;
    font-size: 12px;
}

.email__password__signin input, .email__password__signin input:focus {
    background-color: #1c252f !important;
    color: #8c8c8c !important;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    padding: 4px;
    width: 100%;
    border: none;
    border-color: rgba(0, 0, 0, .12);
    margin: 20px 0px 30px 0px;
    box-shadow: none;
}

.email__password__signin .btn-next {
    color: #000 !important;
    background-color: #ffe01b !important;
    height: 38px;
    min-width: 88px;
    border-radius: 8px;
}

.email__password__signin .btn-cancel {
    background: 0 0;
    border: none;
    border-radius: 2px;
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    height: 38px;
    color: #ffe01b !important;
    border: 1px solid #ffe01b !important;
    border-radius: 8px;
    margin-right: 20px;
}
.modal{
    z-index: 9999 !important;
}

.forgot_password_link{
    cursor: pointer;
}